/* eslint-disable */
export default {
  data() {
    return {
      visible: false,
      appear: false,
      ready: false,
      isVisibleConfig: {
        callback: (value: boolean) => {
          // @ts-ignore
          this.handleVisibility(value)
        },
        intersection: {
          threshold: 1
        }
      }
    };
  },
  watch: {
    visible(value: boolean) {
      // @ts-ignore
      this.appear |= value && this.ready;
    },
    ready(value: boolean) {
      // @ts-ignore
      this.appear |= value && this.visible;
    },
  },
  methods: {
    handleVisibility(e: boolean) {
      // @ts-ignore
      this.visible = e;
    },
    markReady() {
      // @ts-ignore
      this.ready = true;
    },
  },
};
/* eslint-enable */
