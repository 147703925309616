<template>
  <!-- section services in modules -->
  <section>
    <h2 class="font-title text-5xl text-center text-white">
      {{ $t('home.moduleServices.line1') }}
      <br class="xl:block hidden">
      {{ $t('home.moduleServices.line2') }}
    </h2>
    <div class="mt-8 text-center flex flex-nowrap overflow-hidden list-append" v-observe-visibility="isVisibleConfig">
      <template v-if="appear">
        <img v-for="(img, index) in images" :key="img"
             :src="img"
             class="service-logo scaleBoundInFromRight dur-3"
             :class="`dly-${index} ${index === 0 ? 'ml-auto': ''}  ${index !== (images.length - 1) ? 'mr-2': ''}  ${index === (images.length - 1) ? 'mr-auto': ''}`"
             :alt="`Logo ${img}`"
        />
      </template>
    </div>
    <div class="mt-8 flex">
      <router-link :to="{name: $routeNames.Platform}"
                   class="bg-primary-500 text-white px-6 py-2 rounded-full m-auto transition hover:bg-white hover:text-primary-900">
        En savoir plus
      </router-link>
    </div>
  </section>
  <!-- find section services in modules -->
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { preloadImage, resolve } from '../../../_helpers/image.helper';
import hasVisible from '../../../_plugins/visible.mixin';

@Component({
  name: 'home-service-list',
  mixins: [hasVisible],
  data() {
    return {
      images: [
        this.resolve('Symbole__Cours'),
        this.resolve('Symbole__Atelier'),
        this.resolve('Symbole__Capsule'),
        this.resolve('Symbole__Breve'),
        this.resolve('Symbole__Test'),
        // this.resolveSvg('Symbole__Rdv_rose'),
        this.resolve('Symbole__Aide'),
        this.resolve('Symbole__Extras'),
      ],
    };
  },
  computed: {},
  methods: {
    resolve(path) {
      return resolve(`symbole/${path}.png`);
    },
    resolveSvg(path) {
      return resolve(`symbole/${path}.svg`);
    },
    async preloadPath(path) {
      return preloadImage(this.resolve(path));
    },
  },
  mounted() {
    this.solved = [];
    const handleLoaded = (index) => ((img) => {
      this.solved[index] = img;
      if (this.solved.length === this.images.length) {
        this.markReady();
      }
    });
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const index in this.images) {
      const path = this.images[index];
      this.preloadPath(path).then(handleLoaded(index)).catch(handleLoaded(index));
    }
  },
})
export default class HomeServiceList extends Vue {
}
</script>

<style scoped>

</style>
